import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

const Award = ({ data }) => {
  return (
    <div className='award'>
      <div className='award-image'>
        <Img fluid={data.image.childImageSharp.fluid} alt={data.title} />
      </div>
      <div className='award-body'>
        <ReactMarkdown source={data.body}></ReactMarkdown>
      </div>
    </div>
  )
}
export default Award
