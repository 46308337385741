import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'
import Award from '@/components/Award/Award'
import SEO from '@/components/SEO/Seo'

const AwardsPagetemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const awards = data.awards.edges
  const seo = frontmatter.seo
  return (
    <>
      <SEO data={seo} />
      <Hero
        heading={frontmatter.heading}
        fluid={frontmatter.background.childImageSharp.fluid}
      />
      <section className=' flex items-center justify-center bg-white lg:pt-8 pt-20 pb-20 lg:pb-40'>
        <div className='container'>
          <div className='flex justify-between items-start'>
            <Img
              className='awards-img'
              style={{ position: 'relative' }}
              alt='ShiHair photo from outside'
              fluid={frontmatter.image.childImageSharp.fluid}></Img>
            <div className='awards-wrap'>
              {awards &&
                awards.map(({ node: award }) => (
                  <Award key={award.id} data={award.frontmatter}></Award>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const AwardsPage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <AwardsPagetemplate data={data} />
    </Layout>
  )
}

AwardsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query AwardsPagetemplate {
    markdownRemark(frontmatter: { template: { eq: "awards" } }) {
      frontmatter {
        title
        heading
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid(quality: 45, maxWidth: 520) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ...SEO
      }
    }
    awards: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Award" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(quality: 45, maxWidth: 130) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            body
          }
        }
      }
    }
  }
`

export default AwardsPage
